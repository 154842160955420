import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';

import ThreeColumnList from './three-column-list';
import {
    Layout,
    Observer,
    RichTextToHtml,
    ContentfulImage,
} from '../../components';

import styles from './PracticeAreas.module.css';

export const PracticeAreas = ({
    data: {
        contentful: {
            practiceAreasPageCollection: {
                items: [page],
            },
        },
    },
    location,
}) => {
    const {
        heroImage,
        tabTitle,
        practiceAreasText,
        smallDescriptionSectionText,
        smallDescriptionSectionImage,
    } = page;

    const customNodes = {
        [BLOCKS.UL_LIST]: ThreeColumnList,
    };

    const seoData = {
        title: `${tabTitle} - PA Advogados`,
    };

    return (
        <Layout
            seoData={ seoData }
            location={ location }>
            <section className={ styles.hero }>
                <h1 className={ styles.title }>{ tabTitle }</h1>
                <div className={ styles.heroImageWrapper }>
                    <ContentfulImage
                        className={ styles.heroImage }
                        src={ heroImage.url }
                        alt={ heroImage.description } />
                </div>
            </section>
            <Observer threshold={ 0.3 }>
                { (isVisible) => (
                    <section className={ styles.practiceAreas }>
                        <RichTextToHtml
                            withTextAnimation
                            renderNodes={ customNodes }
                            startAnimation={ isVisible }>
                            { practiceAreasText.json }
                        </RichTextToHtml>
                    </section>
                ) }
            </Observer>
            <Observer threshold={ 0.3 }>
                { (isVisible) => (
                    <section className={ styles.descriptionSection }>
                        <RichTextToHtml
                            withTextAnimation
                            startAnimation={ isVisible }>
                            { smallDescriptionSectionText.json }
                        </RichTextToHtml>
                        <div className={ styles.descriptionImageWrapper }>
                            <div className={ styles.descriptionImageContainer }>
                                <ContentfulImage
                                    className={ styles.descriptionImage }
                                    src={ smallDescriptionSectionImage.url }
                                    alt={ smallDescriptionSectionImage.description } />
                            </div>
                        </div>
                    </section>
                ) }
            </Observer>
        </Layout>
    );
};

PracticeAreas.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            practiceAreasPageCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default PracticeAreas;
