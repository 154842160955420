import React from 'react';

import styles from './ThreeColumnList.module.css';

export const ThreeColumnList = (node, children) => {
    const allItems = Array.isArray(children) ? children : [children];
    const itemsPerList = Math.floor(children.length / 3);
    const lists = [];

    for (let index = 0; index < 3; index += 1) {
        lists.push(
            <ul
                className={ styles.list }
                key={ `unordered-list-${index}` }>
                { allItems.slice(itemsPerList * index, itemsPerList * (index + 1)) }
            </ul>,
        );
    }

    return (
        <div className={ styles.wrapper }>
            { lists }
        </div>
    );
};

export default ThreeColumnList;
